import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import frontend from "../frontend.json";
import Lottie from "lottie-react";
import { useStateContext } from "../contexts/ContextProvider";
import { Link } from "react-scroll";

const Home = () => {
  const { nav } = useStateContext();

  return (
    <div name="home" className="w-full h-screen bg-[#0a192f]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-cyan-400">Hola, mi nombre es</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          Adrián Martén
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
          Soy un <span className="text-cyan-400">Full</span>{" "}
          <span className="text-red-500">Stack</span> Developer.
        </h2>
        {!nav && (
          <div style={{ width: "30%" }}>
            <Lottie animationData={frontend} />
          </div>
        )}
        <p className="sm:text-lg flex gap-3 text-[#8892b0] py-4 max-w-[700px]">
          Ubicado en Madrid, España
          <img
            src="https://www.printableworldflags.com/icon-flags/32/Spain.png"
            alt=""
          />
        </p>
        <div>
          <Link to="work" smooth={true} duration={500}>
            <button className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:text-cyan-400 hover:border-cyan-400 duration-200">
              Ver Proyectos
              <span>
                <HiArrowNarrowRight className="ml-3 " />
              </span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
